import styled from '@emotion/styled';
import { FC } from 'react';
import { Container } from '../../styled/components';
import { IconTg, IconVk, IconYt } from '../../icons';
import {
  ABOUT_ROUTE,
  MAIN_ROUTE,
  SUPPORT_ROUTE,
  TELEGRAM_LINK,
  VK_LINK,
  YOUTUBE_LINK,
} from '../../utils/consts';
import { Link } from 'react-router-dom';
import { $phoneWidth, $primaryBg } from '../../styled/variables';
import { FooterBg } from '../../assets/img';

export const Footer: FC = () => {
  //   useEffect(() => {
  //     // Define global functions and objects expected by amoforms.js
  //     window.amo_forms_params = window.amo_forms_params || {
  //       setMeta: function (p) {
  //         this.params = (this.params || []).concat([p]);
  //       },
  //     };

  //     window.amo_forms_load =
  //       window.amo_forms_load ||
  //       function (f) {
  //         window.amo_forms_load.f = (window.amo_forms_load.f || []).concat([f]);
  //       };

  //     window.amo_forms_loaded =
  //       window.amo_forms_loaded ||
  //       function (f, k) {
  //         window.amo_forms_loaded.f = (window.amo_forms_loaded.f || []).concat([[f, k]]);
  //       };

  //     // Initialize the form
  //     window.amo_forms_load({
  //       id: '1353650',
  //       hash: '16ba1607e0d5632610d09f7b839fad84',
  //       locale: 'ru',
  //     });

  //     // Load the form script
  //     const script = document.createElement('script');
  //     script.src = 'https://forms.amocrm.ru/forms/assets/js/amoforms.js?1720779984';
  //     script.async = true;
  //     script.charset = 'utf-8';
  //     script.id = 'amoforms_script_1353650';
  //     document.getElementById('amoForm')?.appendChild(script);
  //   }, []);

  return (
    <Wrapper>
      <AmoForm id="amoForm">
        <Text>
          <Title>
            4 ноября мы завершили приём заявок на участие в премии «Учитель истории 2024».
          </Title>
          <Descr>
            <p>Если вы собирались, но не успели подать заявку — ждём вас в следующем году!</p>
            <p>
              Мы только начали наше общее дело — многое ещё впереди. Чтобы не пропустить новости о
              нашем учительском сообществе, о следующей премии и о наших новых проектах — оставьте
              свою электронную почту, и мы будем держать вас в курсе.
            </p>
            <p>С наилучшими пожеланиями, Фонд Румянцева.</p>
          </Descr>
        </Text>
        <Form
          id="unisender-subscribe-form"
          data-url="https://cp.unisender.com/ru/v5/subscribe-form/view/617f3bbxg7w5fzcb87kuayuhdyg9dhm97aetooue"
          data-settings="6krudtpdykk3d3httffmxbu91777drwkheprkfh8t85hh3mbrwr4o"></Form>
      </AmoForm>
      <MyContainer>
        <Left>
          <Socials>
            <a href={VK_LINK} target="_blank">
              <IconVk />
            </a>
            <a href={`https://t.me/${TELEGRAM_LINK}`} target="_blank">
              <IconTg />
            </a>
            <a href={YOUTUBE_LINK} target="_blank">
              <IconYt />
            </a>
          </Socials>
          <Links>
            <Link to={MAIN_ROUTE}>Премия</Link>
            <Link to={SUPPORT_ROUTE}>Поддержать</Link>
          </Links>
          <AboutLinks>
            <Col>
              <Link className="main-link" to={ABOUT_ROUTE}>
                О нас
              </Link>
              <a href={`${ABOUT_ROUTE}#values`}>Ценности</a>
              <a href={`${ABOUT_ROUTE}#team`}>Команда</a>
              <a href={`${ABOUT_ROUTE}#support`}>Партнеры</a>
            </Col>
            <Col>
              <a href={`${ABOUT_ROUTE}#docs`}>Документы</a>
              <a href={`${ABOUT_ROUTE}#contacts`}>Контакты</a>
              <a href={`${ABOUT_ROUTE}#faq`}>F.A.Q.</a>
            </Col>
          </AboutLinks>
        </Left>
        <Copy>© БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П. РУМЯНЦЕВА {new Date().getFullYear()}</Copy>
        <Bg src={FooterBg} />
      </MyContainer>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  overflow: hidden;
  padding: 3.5rem 0 0;
  background-color: ${$primaryBg};
  color: #fff;
  a {
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.75;
    }
  }
`;

const Form = styled.div`
  background: transparent !important;
  > * {
    span,
    b {
      color: #fff !important;
    }
  }
`;

const MyContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 8rem;
  padding-bottom: 2rem;
  > *:not(:last-child) {
    position: relative;
    z-index: 1;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    flex-direction: column;
    background-color: #1a4919;
    margin-top: 4rem;
  }
`;

const Bg = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  z-index: 0;
  @media screen and (max-width: ${$phoneWidth}) {
    bottom: auto;
    top: 0;
    transform: translate(-50%, -100%);
  }
`;

const Left = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 3rem;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    flex-direction: column;
    > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const Socials = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    width: 2.75rem;
    height: 2.75rem;
    fill: #fff;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    flex-direction: row;
    order: 1;
    justify-content: center;
    margin-top: 3rem;
    > *:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.94rem;
  font-weight: 700;
  line-height: 2.88rem;
  text-transform: uppercase;
  > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    align-items: center;
    margin-bottom: 3rem;
  }
`;

const AboutLinks = styled.div`
  display: flex;
  align-items: flex-end;
  > *:not(:last-child) {
    margin-right: 3rem;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    flex-direction: column;
    align-items: flex-start;
    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  a {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5rem;
    &.main-link {
      font-size: 1.94rem;
      font-weight: 700;
      line-height: 2.88rem;
      letter-spacing: 0%;
      text-align: left;
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: ${$phoneWidth}) {
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    > *:not(:last-child) {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
    a {
      &.main-link {
        display: none;
      }
    }
  }
`;

const Copy = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-top: auto;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-top: 2rem;
    text-align: center;
  }
`;

const AmoForm = styled(Container)`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  #amoCRMFormContainer {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    > * {
      width: 100%;
    }
  }

  @media screen and (max-width: ${$phoneWidth}) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 900;
  line-height: 100%;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 4rem;
  width: 45rem;
  margin-bottom: 2rem;
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 2rem;
    line-height: 100%;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const Descr = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  max-width: 46rem;
  opacity: 0.75;
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 1rem;
    margin-bottom: 2rem;
    line-height: 1.25rem;
    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;
