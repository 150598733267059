import styled from '@emotion/styled';
import { FC } from 'react';
import { Container, Title } from '../../styled/components';
import { $phoneWidth, $primaryBg } from '../../styled/variables';

export const Contacts: FC = () => {
  return (
    <Wrapper id="contacts">
      <Title>контакты</Title>
      <Content>
        <Item>
          <Label>Для предложений о сотрудничестве: </Label>
          <Link href={`mailto:tihonov@uchitelistorii.ru`} target="_blank">
            tihonov@uchitelistorii.ru
          </Link>
        </Item>
        <Item>
          <Label>Для прессы:</Label>
          <Link href={`mailto:pr@uchitelistorii.ru`} target="_blank">
            pr@uchitelistorii.ru
          </Link>
        </Item>
        <Item>
          <Label>По общим вопросам и конкурсу «Учитель истории»: </Label>
          <Link href={'tel:+74951345099'} target="_blank">
            +74951345099
          </Link>
          <Link href={`mailto:manager@uchitelistorii.ru`} target="_blank">
            manager@uchitelistorii.ru
          </Link>
          <Link href="https://wa.me/79931001947" target="_blank">
            WhatsApp
          </Link>
          <Link href="https://t.me/PetrTikhonov_FR" target="_blank">
            Telegram
          </Link>
        </Item>
        <Item>
          <Label>Адрес для корреспонденции:</Label>
          <Link href="https://yandex.ru/maps/-/CDdCq-1S" target="_blank">
            г. Москва, ул. Пятницкая, д. 37, пом. 1/1
          </Link>
        </Item>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: baseline;
  gap: 3rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 6rem;
    padding-bottom: 6rem;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  @media screen and (max-width: ${$phoneWidth}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const Link = styled.a`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  transition: color 0.3s;
  &:hover {
    color: ${$primaryBg};
  }
`;

const Label = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
`;
