import { FC } from 'react';
import { OfferImg } from '../../assets/img';
import { Button } from '../../components/form';
import styled from '@emotion/styled';
import { Container } from '../../styled/components';
import { $phoneWidth } from '../../styled/variables';
// import { handleToggleAmoForm } from '../../components/fn';
import { useNavigate } from 'react-router-dom';
import { SUPPORT_ROUTE } from '../../utils/consts';

export const Offer: FC = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Banner>
        <img src={OfferImg} />
      </Banner>
      <Content>
        <Title>
          россия начинается <br /> в школе
        </Title>
        <Descr>
          Развитие образования в России — наше общее <br />
          дело. Изменить школу к лучшему способно <br />
          только деятельное сообщество российских <br />
          учителей. Ради его поддержки мы создали премию «Учитель истории».
        </Descr>
        <Buttons>
          <Button onClick={() => navigate(SUPPORT_ROUTE)} $theme="secondary">
            поддержать
          </Button>
          {/* <Button onClick={handleToggleAmoForm}>подать заявку</Button> */}
        </Buttons>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: calc(100vh - 8.5rem);
  max-height: 62rem;
  @media screen and (max-width: ${$phoneWidth}) {
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    height: auto;
    max-height: none;
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-right: 1.5rem;
  img {
    width: 100%;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 47.6875rem;
  height: 100%;
  max-width: 39.5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    height: auto;
    max-height: none;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 7.69rem;
  font-weight: 900;
  line-height: 6.56rem;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 4rem;
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 4rem;
    line-height: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const Descr = styled.p`
  font-family: 'Meta Pro';
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 4rem;
  @media screen and (max-width: ${$phoneWidth}) {
    text-align: center;
    margin-bottom: 2rem;
    br {
      display: none;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  > * {
    width: 50%;
  }
  > *:not(:last-child) {
    margin-right: 1.5rem;
  }
  @media screen and (max-width: ${$phoneWidth}) {
    flex-direction: column;
    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
`;
