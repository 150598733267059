import styled from '@emotion/styled';
import { FC } from 'react';
import { Container } from '../../styled/components';
import { DocImageSvg } from '../../assets/img';
import { $phoneWidth } from '../../styled/variables';
import { CertificatePDF, CharterPDF, PositionPDF, RequisitesPDF } from '../../assets/pdf';

export const Docs: FC = () => {
  return (
    <Wrapper id="docs">
      {/* <MyTitle>реквизиты и документы</MyTitle> */}
      <Row>
        <Col>
          <Label>
            РЕКВИЗИТЫ <br /> И УЧРЕДИТЕЛЬНЫЕ <br /> ДОКУМЕНТЫ
          </Label>
          <DocsWrap>
            <Doc target="_blank" href={CertificatePDF}>
              <img src={DocImageSvg} />
              <Text>
                Свидетельство <br /> о регистрации
              </Text>
            </Doc>
            <Doc target="_blank" href={RequisitesPDF}>
              <img src={DocImageSvg} />
              <Text>
                Реквизиты <br /> фонда
              </Text>
            </Doc>
            <Doc target="_blank" href={CharterPDF}>
              <img src={DocImageSvg} />
              <Text>Устав</Text>
            </Doc>
            <Doc target="_blank" href={PositionPDF}>
              <img src={DocImageSvg} />
              <Text>Положение о конкурсе</Text>
            </Doc>
          </DocsWrap>
        </Col>
        {/* <Col>
          <Label>
            Отчеты <br /> о деятельности
            <br /> фонда
          </Label>
          <DocsWrap>
            <Doc>
              <img src={DocImage} />
              <Text>Минюст</Text>
            </Doc>
            <Doc>
              <img src={DocImage} />
              <Text>
                Публичный <br /> годовой отчет
              </Text>
            </Doc>
          </DocsWrap>
        </Col> */}
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding-top: 8rem;
  @media screen and (max-width: ${$phoneWidth}) {
    padding-top: 6rem;
  }
`;

// const MyTitle = styled(Title)`
//   margin-bottom: 4rem;
//   @media screen and (max-width: ${$phoneWidth}) {
//     display: none;
//   }
// `;

const Row = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: baseline;
  grid-template-columns: 1fr;
  gap: 5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    grid-template-columns: 1fr;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Label = styled.div`
  font-size: 3.13rem;
  font-weight: 700;
  line-height: 2.94rem;
  letter-spacing: 0%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
  @media screen and (max-width: ${$phoneWidth}) {
    font-size: 2rem;
    line-height: 100%;
    margin-bottom: 0;
  }
`;

const DocsWrap = styled.div`
  display: grid;
  align-content: baseline;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    gap: 1rem;
    grid-template-columns: 1fr;
  }
`;

const Doc = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24rem;
  height: 24rem;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.div`
  position: relative;
  z-index: 1;
  font-size: 1.94rem;
  font-weight: 400;
  line-height: 2rem;
`;
