/* eslint-disable */

import { FC } from 'react';
import { Container } from '../../styled/components';
import styled from '@emotion/styled';
const PublicOffer: FC = () => {
  return (
    <Wrapper>
      <Content>
        {`Публичная оферта о заключении договора добровольного
благотворительного пожертвования
БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П. РУМЯНЦЕВА, именуемый далее
«Фонд», в лице исполнительного директора Тихонова Петра Петровича
настоящим предлагает любому физическому лицу, именуемому далее
«Благотворитель», заключить Договор пожертвования на следующих условиях:
1. Общие положения
1.1. Данное предложение является публичной офертой в соответствии с
пунктом 2 статьи 4З7 Гражданского кодекса Российской Федерации.
1.2. Акцептом (принятием) настоящей оферты является перечисление
Благотворителем денежных средств на расчетный счет Фонда в качестве
добровольного благотворительного пожертвования (далее – Благотворительное
пожертвование). Акцепт настоящей оферты Благотворителем означает, что
последний, полностью дееспособное физическое лицо, ознакомился и согласен
со всеми условиями настоящего Договора о добровольном благотворительном
пожертвовании (далее – Договор).
1.3. Оферта вступает в силу со дня ее публикации на официальном сайте
Фонда учитель-истории.рф.
1.4. Текст настоящей оферты может быть изменен Фондом без
предварительного уведомления, изменения действуют со дня его размещения
на официальном сайте Фонда учитель-истории.рф, если иное не будет
предусмотрено в решении об изменении / дополнении условий Оферты.
1.5. Оферта является бессрочной и действует до дня размещения на
официальном сайте Фонда учитель-истории.рф
Фонд вправе отменить Оферту в любое время без объяснения причин.
1.6. Недействительность одного или нескольких условий не отменяет всех
остальных условий Оферты.

1.7. Фонд готов заключать договоры благотворительного пожертвования в ином
порядке и (или) на иных условиях, нежели это предусмотрено Офертой, для
чего любое заинтересованное лицо вправе обратиться для заключения
соответствующего договора в Фонд.
1.8. Принимая условия данного соглашения, Благотворитель подтверждает
добровольный и безвозмездный характер Благотворительного пожертвования.
1.9. Выполнение Благотворителем действий по настоящему Договору является
пожертвованием в соответствии со статьей 582 Гражданского кодекса
Российской Федерации.
2. Предмет Договора
2.1. По настоящему Договору Благотворитель в качестве добровольного
Благотворительного пожертвования передает Фонду собственные денежные
средства любым удобным для Благотворителя способом, а Фонд принимает
Благотворительное пожертвование и использует его для осуществления
деятельности в соответствии с Уставом. Факт передачи Благотворительного
пожертвования свидетельствует о полном согласии Благотворителя с
условиями настоящего Договора.
2.2. Благотворитель подтверждает и гарантирует Фонду, что передаваемые
Фонду денежные средства принадлежат Благотворителю на праве
собственности, правами третьих лиц не обременены, и безвозмездная
передача Благотворителем Фонду указанных денежных средств не нарушает
права третьих лиц и нормы действующего законодательства Российской
Федерации.
2.3. Благотворительное пожертвование передается в собственность Фонда.
Благотворительное пожертвование является для Фонда целевым поступлением
в соответствии с п. 2 ст.251 НК РФ на осуществление Фондом уставной
деятельности.
В соответствии с Уставом Фонд осуществляет следующую деятельность:

● привлечение спонсорской и благотворительной помощи, сбор
пожертвований от организаций и частных лиц;
● аккумулирование имущества и направление его на цели Фонда,
определенные Уставом;
● реализация программ по привлечению добровольных пожертвований;
● выступление инициатором и координатором проектов и программ,
направленных на развитие уставных целей Фонда;
● участие и (или) организация различных мероприятий, направленных на
развитие сотрудничества в области благотворительности,
здравоохранения и социальной поддержки граждан, создание
благоприятных условий для ведения благотворительной деятельности,
продвижение и пропаганду идей благотворительности;
● содействие в реализации дошкольного и общего образования,
дополнительного образования детей;
● содействие строительству и развитию инфраструктуры в области
образования и науки, спорта и культуры;
● содействие в организации профессиональной ориентации граждан в
целях выбора сферы деятельности (профессии), трудоустройства,
прохождения профессионального обучения и получения
дополнительного профессионального образования;
● содействие в разработке и осуществлении общественно значимых
программ, проектов и мероприятий;
● поддержка и развитие интеллектуального и творческого потенциала
граждан в сферах образования, науки, культуры, физической культуры и
спорта, досуга;
● содействие реализации проектов, направленных на поддержку и
развитие образования, физической культуры и спорта, искусства и науки;
● содействие объединению усилий активных членов гражданского
общества, деятелей культуры, искусства, науки и спорта, направленных
на всестороннее укрепление гармоничного развития личности;
● содействие формированию научно-идеологической,
финансово-экономической и материально-технической базы Фонда для
воплощения в жизнь его проектов, основанных на общечеловеческих
принципах сотрудничества и гуманизма;

● создание условий для использования памятников культурного наследия в
просветительских и культурных проектах;
● участие, организация и (или) проведение, в том числе
благотворительных, культурно-зрелищных, культурно-массовых
развлекательных, просветительских, спортивных,
физкультурно-оздоровительных, творческих мероприятий, конкурсов,
выставок, форумов, конгрессов, конференций, ярмарок, акций,
направленных на достижение уставных целей Фонда, участие в
выставках и презентациях;
● содействие в организации съемок, телевизионных и радиотрансляций,
аудио- и видеоматериалов о социально значимых программах и
проектах;
● содействие в распространении теле- и видеопродукции о социально
значимых программах и проектах;
● опубликование и иные способы распространения информации,
связанной с целями деятельности Фонда;
● содействие выпуску и распространению печатной, аудио- и
видеопродукции художественного, научного, учебного и
просветительского характера, связанной с целями деятельности Фонда;
● взаимодействие с органами государственной власти Российской
Федерации и органами местного самоуправления, в соответствии с
целями создания Фонда для достижения поставленных целей Фонда;
● привлечение к своей работе специалистов на договорных условиях;
● оказание финансовой, организационной, информационной, юридической
поддержки инициатив (предложений, проектов, программ и т.д.) граждан
и организаций, соответствующих целям Фонда.
2.4. Благотворитель подтверждает свое согласие на самостоятельное
перераспределение на общеполезные цели Фондом пожертвования
полученного пожертвования без отдельного согласования с Благотворителем в
том случае, если использование пожертвования в соответствии с указанным
Благотворителем назначением становится вследствие изменившихся
обстоятельств невозможным.

2.5. Срок расходования Благотворительного пожертвования не
устанавливается.
3. Внесение Благотворительного пожертвования
3.1. Благотворитель самостоятельно определяет размер суммы
Благотворительного пожертвования и вносит его путем перечисления денежных
средств на банковский счет Фонда любым платежным способом, указанным на
официальном сайте Фонда учитель-истории.рф на условиях настоящего
Договора.
3.2. Факт перечисления Благотворительного пожертвования на счет Фонда в
рамках проектов Фонда свидетельствует о полном согласии Благотворителя с
условиями настоящих Оферты и Договора.
3.3. Датой акцепта Оферты и, соответственно, датой заключения настоящего
Договора является: дата поступления денежных средств Благотворителя на
расчетный счет Фонда либо, в соответствующих случаях, на счет Фонда в
платежной системе.
4. Права и обязанности сторон
4.1. Фонд обязуется использовать полученные от Благотворителя в качестве
Благотворительного пожертвования денежные средства строго в соответствии с
действующим законодательством Российской Федерации и в рамках уставной
деятельности.
4.2. Благотворительное пожертвование направляется на достижение уставных
целей деятельности.
4.3. Фонд вправе самостоятельно определять направление расходования
Благотворительного пожертвования, полученное для реализации уставных
целей.
4.4. Благотворитель дает Фонду согласие на обработку предоставленных
Благотворителем при осуществлении Благотворительного пожертвования
персональных данных (ФИО, адрес, место жительства, адрес электронной

почты, телефон, банковские реквизиты), в том числе третьим лицам (на
основании договора с Фондом), в том числе предоставления персональных
данных операторам электронных платежных систем, осуществляющих
перечисление денежных средств Благотворителя на банковский расчётный счет
Фонда, для целей исполнения настоящего Договора, включая следующие
действия: сбор, запись, систематизацию, накопление, хранение, уточнение
(обновление, изменение), извлечение, использование, обезличивание,
блокирование, удаление, уничтожение персональных данных.
4.5. Фонд обязуется не раскрывать третьим лицам информацию о
Благотворителе без его согласия, за исключением случаев, предусмотренных
законодательством.
4.6. Адреса электронной почты Благотворителя могут использоваться Фондом
для направления информационных сообщений о благотворительных проектах
Фонда и ходе их реализации. Благотворитель имеет право отказаться от
получения таких сообщений в будущем.
4.7. Благотворитель вправе:
— контролировать использование Благотворительного пожертвования по
целевому назначению и в любое время потребовать письменного отчета об
использовании полученного Фондом Благотворительного пожертвования;
— требовать отмены Благотворительного пожертвования и возврата
пожертвования Благотворителю (его правопреемникам) в случае, если у
Благотворителя (его правопреемников) имеются доказательства использования
переданного по Договору Благотворительного пожертвования не в соответствии
с целевым назначением.
4.8. Фонд обязуется:
— использовать полученное по Договору Благотворительное пожертвование
исключительно для реализации целей, указанных в настоящей Оферте;
— вести обособленный учет всех операций по использованию
Благотворительного пожертвования;

— предоставлять Благотворителю по его требования отчет об использовании
Благотворительного пожертвования, а также предоставлять ему возможность
знакомиться с финансовой, бухгалтерской и иной документацией,
свидетельствующей об использовании Благотворительного пожертвования;
— предпринимать иные действия, необходимые для совершения и исполнения
Оферты.
4.9. Фонд не несет перед Благотворителем иных обязательств, кроме
обязательств, указанных в настоящем Договоре.
5. Прочие условия
Споры и разногласия Стороны будут по возможности разрешаться путем
переговоров. В случае невозможности разрешения спора путем переговоров
споры и разногласия могут решаться в соответствии с действующим
законодательством Российской Федерации в судебных инстанциях по месту
нахождения Фонда.
6. Реквизиты
БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ИМ. Н.П. РУМЯНЦЕВА
ОГРН: 1247700425862
ИНН: 9705226363
КПП: 770501001
Адрес местонахождения: 119017, город Москва, Пятницкая ул, д. 37, помещ. 1/1
Банковские реквизиты:
Р/с: 40703810120000002957
в ООО "Банк Точка"
БИК: 044525104
К/с: 30101810745374525104

Исполнительный директор БЛАГОТВОРИТЕЛЬНОГО ФОНД ИМ. Н.П.
РУМЯНЦЕВА
Тихонов Петр Петрович`}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  padding: 20px;
`;

const Content = styled.pre`
  white-space: pre-wrap;
  line-height: 1.5;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  color: #000;
  width: fit-content;
  margin: 0 auto;
`;

export default PublicOffer;
