import styled from '@emotion/styled';
import { FC } from 'react';
import { Container } from '../../styled/components';
import { Link, NavLink } from 'react-router-dom';
import { IconLogo } from '../../icons';
import { ABOUT_ROUTE, MAIN_ROUTE, SUPPORT_ROUTE } from '../../utils/consts';
import { Button } from '../../components/form';
import { $phoneWidth, $primaryBg, $primaryColor } from '../../styled/variables';
import { handleToggleAmoForm } from '../../components/fn';

export const Header: FC = () => {
  return (
    <Wrapper>
      <MyContainer>
        <Logo to={MAIN_ROUTE}>
          <IconLogo />
        </Logo>
        <Nav>
          <NavLink to={ABOUT_ROUTE}>О нас</NavLink>
          <NavLink to={SUPPORT_ROUTE}>Поддержать</NavLink>
          {/* <div onClick={handleToggleAmoForm}>Регистрация</div> */}
        </Nav>
        <FormBtn onClick={handleToggleAmoForm} $fitContent>
          Получать обновления
        </FormBtn>
      </MyContainer>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
`;

const MyContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media screen and (max-width: ${$phoneWidth}) {
    justify-content: space-between;
  }
`;

const Logo = styled(Link)`
  display: flex;
  margin-right: 6rem;
  svg {
    width: 8.31rem;
    fill: ${$primaryColor};
    transition: fill 0.3s;
  }
  &:hover {
    svg {
      fill: ${$primaryBg};
    }
  }
  @media screen and (max-width: ${$phoneWidth}) {
    width: 5rem;
    margin-right: 0;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  > * {
    cursor: pointer;
    position: relative;
    font-size: 1.94rem;
    font-weight: 700;
    line-height: 2.88rem;
    color: ${$primaryColor};
    text-transform: uppercase;
    transition: color 0.3s;
    &::before {
      position: absolute;
      content: '';
      bottom: -0.125rem;
      left: 0;
      width: 0;
      height: 0.25rem;
      background-color: ${$primaryColor};
      transition: width 0.3s, background-color 0.3s;
    }
    &:hover {
      color: ${$primaryBg};
      &::before {
        background-color: ${$primaryBg};
      }
    }
    &:not(:last-child) {
      margin-right: 6rem;
    }
    &.active {
      &:before {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: ${$phoneWidth}) {
    > * {
      font-size: 1rem;
      &:not(a) {
        display: none;
      }
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
`;

const FormBtn = styled(Button)`
  margin-left: auto;
  height: 5rem;
  font-size: 1.5rem;
  @media screen and (max-width: ${$phoneWidth}) {
    margin-left: 0;
    width: 6rem;
    font-size: 1rem;
    line-height: 100%;
    height: fit-content;
  }
`;
